.project-header-toggle {
  border: none;
  background: rgb(255, 99, 120);
  color: white;
  transition: all 1s ease-in-out;
}

.project-header-toggle.ant-btn:hover,
.project-header-toggle.ant-btn:focus,
.project-header-toggle.ant-btn:active,
.project-header-toggle.ant-btn.active {
  border: none;
  background: rgb(255, 99, 120);
  color: white;
}

.example-appear {
  opacity: 0.01;
}

.example-appear.example-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in;
}
